import { useState, useEffect } from "react";
import {
  Col,
  Collapse,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";

import { EnumsValues } from "../../../shared";
import { isCreditCardValid } from "../../../shared/utils";
import useLocationAndPostalCodes from "../../../shared/hooks/useLocationAndPostalCodes";
import { formNames } from "../utils/formNames";
import { IRenovation } from "../../../services/Renovation";
import { IProvince } from "../../../services/Province";

const { Panel } = Collapse;

interface IUserInformationProps {
  paymentOptions: () => JSX.Element[] | false;
  form: FormInstance;
  provinceOptionsId: () => false | JSX.Element[];
  renovationData: IRenovation | undefined;
  provinces: IProvince[];
  readOnlyOr: boolean;
  paymentInstallmentTypesOptions: () => JSX.Element[] | false;
}

export const UserInformationForm = (
  props: IUserInformationProps
): JSX.Element => {
  const {
    paymentOptions,
    form,
    provinceOptionsId,
    renovationData,
    provinces,
    readOnlyOr,
    paymentInstallmentTypesOptions
  } = props;

  const {
    USER_EMAIL,
    USER_HOME,
    USER_HOME_DEPARTMENT,
    USER_HOME_FLOOR,
    USER_HOME_NUMBER,
    USER_LOCATION,
    USER_PAYMENT_METHOD,
    USER_PAYMENT_NUMBER,
    USER_PHONE,
    USER_POSTAL_CODE,
    USER_PROVINCE,
    title,
    USER_PAYMENT_INSTALLMENT_TYPES
  } = formNames.userInformation;
  const [isCuponPaymentSelected, setIsCuponPaymentSelected] =
    useState<boolean>(false);

  const [provinceIdSelected, setProvinceIdSelected] = useState<number>(0);
  const [selectedlocalidad, setSelectedlocalidad] = useState<string>("");
  const [provinceSelected, setProvinceSelected] = useState<boolean>(false);
  const [locationSelected, setLocationSelected] = useState<boolean>(false);
  const [postalCodeSelected, setPostalCodeSelectedd] = useState<boolean>(false);
  const [paymentInstallmentTypeSelected, setPaymentInstallmentTypeSelected] = useState<string>("");

  const { locationOptions, postalCodeOptions, localidades, postalCodes } =
    useLocationAndPostalCodes({
      selectedlocalidad,
      provinceIdSelected,
      formItemLocationName: USER_LOCATION.name,
      formItemPostalCodeName: USER_POSTAL_CODE.name,
      form,
    });

  useEffect(() => {
    if (renovationData && renovationData.policy) {
      const lastEndoso =
        renovationData?.policy.endoso[renovationData?.policy.endoso.length - 1];
      const paymentMethod = lastEndoso?.paymentMethod[0];

      paymentMethod === EnumsValues.PaymentTypeIdLiderar.CUPONES
        ? setIsCuponPaymentSelected(true)
        : setIsCuponPaymentSelected(false);
    }
  }, [renovationData]);

  useEffect(() => {
    if (renovationData && provinces.length && !provinceSelected) {
      const provinceIdLiderar = renovationData.insured_address_province_id;
      const selectedProvince = provinces.find(
        (province) => province.id_liderar === provinceIdLiderar
      );
      if (selectedProvince) {
        setProvinceIdSelected(selectedProvince.id);
      }
      form.setFieldsValue({
        [USER_PROVINCE.name]: provinceIdLiderar,
      });
      setProvinceSelected(true);
    }
  }, [renovationData, provinces]);

  useEffect(() => {
    if (renovationData && localidades.length && !locationSelected) {
      let locationIdLiderar = renovationData.insured_address_city;
      if (locationIdLiderar === "CIUDAD AUTONOMA de BUENOS AIRES") {
        locationIdLiderar = "CAPITAL";
      }
      setSelectedlocalidad(locationIdLiderar);
      form.setFieldsValue({
        [USER_LOCATION.name]: locationIdLiderar,
      });
      setLocationSelected(true);
    }
  }, [renovationData, localidades]);

  useEffect(() => {
    if (renovationData && postalCodes.length && !postalCodeSelected) {
      form.setFieldsValue({
        [USER_POSTAL_CODE.name]: renovationData.insured_address_postal_code,
      });
      setPostalCodeSelectedd(true);
    }
  }, [postalCodes, renovationData]);

  const handleSelectPaymentType = (idLiderar: number) => {
    form.setFieldsValue({
      [USER_PAYMENT_NUMBER.name]: "",
    });
    if (String(idLiderar) === EnumsValues.PaymentTypeIdLiderar.CUPONES) {
      setIsCuponPaymentSelected(true);
    } else {
      setIsCuponPaymentSelected(false);
    }
  };

  const handleChangeProvince = (idLiderar: number, option: any) => {
    setProvinceIdSelected(Number(option.key));
    window.postMessage({ evento: 'updateRemolque' }, "*");
  };

  const handleChangeLocationSelect = (location: string) => {
    setSelectedlocalidad(location);
    window.postMessage({ evento: 'updateRemolque' }, "*");
  };

  const handleSelectPaymentInstallmentType = (code: string) => {
    setPaymentInstallmentTypeSelected(code);
  }
  useEffect(() => {
    if (renovationData && paymentInstallmentTypesOptions.length && !paymentInstallmentTypeSelected) {
      const codeLiderar = renovationData.insured_address_province_id;
      form.setFieldsValue({
        [USER_PAYMENT_METHOD.name]: codeLiderar,
      });
    }
  }, [renovationData, paymentInstallmentTypeSelected]);

  return (
    <Collapse defaultActiveKey={["1"]} className="collapse">
      <Panel header={title} key="1">
        <Row justify="space-between" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[{ required: true, message: "Ingrese domicilio" }]}
              label={USER_HOME.label}
              name={USER_HOME.name}
            >
              <Input
                placeholder={USER_HOME.placeholder}
                type="text"
                maxLength={30}
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                { required: true, message: "Ingrese número" },
                {
                  pattern: /^[\d]{0,40}$/,
                  message: "Máximo 40 dígitos",
                },
              ]}
              label={USER_HOME_NUMBER.label}
              name={USER_HOME_NUMBER.name}
            >
              <InputNumber
                placeholder={USER_HOME_NUMBER.placeholder}
                type="number"
                controls={false}
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={USER_HOME_FLOOR.label}
              name={USER_HOME_FLOOR.name}
            >
              <Input
                type="text"
                placeholder={USER_HOME_FLOOR.placeholder}
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={USER_HOME_DEPARTMENT.label}
              name={USER_HOME_DEPARTMENT.name}
            >
              <Input
                type="string"
                placeholder={USER_HOME_DEPARTMENT.placeholder}
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
        </Row>{" "}
        <Row justify="space-between" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                { required: true, message: "Ingrese teléfono" },
                {
                  pattern: /^[\d]{0,30}$/,
                  message: "Máximo 30 dígitos",
                },
              ]}
              label={USER_PHONE.label}
              name={USER_PHONE.name}
            >
              <InputNumber
                type="number"
                controls={false}
                placeholder={USER_PHONE.placeholder}
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[{ required: true, message: "Seleccione provincia" }]}
              label={USER_PROVINCE.label}
              name={USER_PROVINCE.name}
            >
              <Select
                placeholder={USER_PROVINCE.placeholder}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                onChange={handleChangeProvince}
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              >
                {provinceOptionsId()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[{ required: true, message: "Seleccione localidad" }]}
              label={USER_LOCATION.label}
              name={USER_LOCATION.name}
            >
              <Select
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                disabled={
                  form.getFieldsValue([USER_PROVINCE.name]) === undefined ||
                  readOnlyOr
                }
                placeholder={USER_LOCATION.placeholder}
                onSelect={handleChangeLocationSelect}
                className={readOnlyOr ? "read-only" : undefined}
              >
                {locationOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Seleccione código postal",
                },
              ]}
              label={USER_POSTAL_CODE.label}
              name={USER_POSTAL_CODE.name}
            >
              <Select
                showSearch
                placeholder={USER_POSTAL_CODE.placeholder}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                disabled={
                  form.getFieldsValue([USER_LOCATION.name]) === undefined ||
                  readOnlyOr
                }
                className={readOnlyOr ? "read-only" : undefined}
              >
                {postalCodeOptions()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  type: "email",
                  message: "Debe ingresar un email válido",
                },
                { required: true, message: "Ingrese email" },
              ]}
              label={USER_EMAIL.label}
              name={USER_EMAIL.name}
            >
              <Input
                placeholder={USER_EMAIL.placeholder}
                type="email"
                maxLength={100}
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[{ required: true, message: "Seleccione tipo de pago" }]}
              label={USER_PAYMENT_METHOD.label}
              name={USER_PAYMENT_METHOD.name}
            >
              <Select
                placeholder={USER_PAYMENT_METHOD.placeholder}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                onSelect={handleSelectPaymentType}
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              >
                {paymentOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6} hidden={isCuponPaymentSelected}>
            <Form.Item
              rules={[
                {
                  required: !isCuponPaymentSelected,
                  message: "Ingrese número de pago",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const lastEndoso =
                      renovationData?.policy?.endoso[
                        renovationData?.policy?.endoso.length - 1
                      ];
                    const paymentNumber = value;
                    const paymentNumberFromBackend =
                      lastEndoso?.paymentMethod[1];
                    const paymentType = getFieldValue(USER_PAYMENT_METHOD.name);
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (paymentNumber === paymentNumberFromBackend) {
                      return Promise.resolve();
                    }
                    if (isCreditCardValid(paymentNumber, paymentType)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        paymentType === EnumsValues.PaymentTypeIdLiderar.CBU
                          ? "El número de CBU es inválido"
                          : "El número de tarjeta es inválido"
                      )
                    );
                  },
                }),
              ]}
              label={USER_PAYMENT_NUMBER.label}
              name={USER_PAYMENT_NUMBER.name}
              dependencies={[USER_PAYMENT_METHOD.name]}
            >
              <Input
                type="text"
                placeholder={USER_PAYMENT_NUMBER.placeholder}
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[{ required: true, message: "Seleccione tipo de cuotas" }]}
              label={USER_PAYMENT_INSTALLMENT_TYPES.label}
              name={USER_PAYMENT_INSTALLMENT_TYPES.name}
            >
              <Select
                placeholder={USER_PAYMENT_INSTALLMENT_TYPES.placeholder}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                onSelect={handleSelectPaymentInstallmentType}
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              >
                {paymentInstallmentTypesOptions()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default UserInformationForm;
