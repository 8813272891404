import { useState, useEffect, useCallback, useContext, useRef } from "react";
import { RouteConfigComponentProps } from "react-router-config";
import { useHistory } from "react-router";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Collapse,
  Divider,
  Tag,
  Switch,
  InputNumber,
} from "antd";
import { RefSelectProps, SelectValue } from "antd/lib/select";
import GraphqlService from "../../services/graphql/GraphqlService";
import { Query } from "../../services/graphql/query";
import SelectWithFetch from "../utils/SelectWithFetch";
import { ContextPoliza } from "../../shared/ContextPoliza";
import { IProvince } from "../../services/Province";
import { IProducer } from "../../services/Producer";
import { Mutation } from "../../services/graphql/mutation";
import { IFiscalCondition } from "../../services/FiscalCondition";
import { ICoveragePeriod } from "../../services/CoveragePeriod";
import {
  IbrandModelByBrandList,
  IbrandModelByBrandModelYear,
  IyearsByBrandAndModel,
} from "../../services/BrandModel";
import { useQuotation } from "../../shared/hooks/useQuotation";
import { camelCase, getMaxAmount, getMinAmount } from "././../../shared/utils";
import { IVehicleType } from "../../services/IVehicleType";
import { IQuotationInput } from "../../services/IQuotationInput";
import { ContextApp, CustomMessage, EnumsValues } from "../../shared";
import { IForm } from "../../services/IForm";
import { IBrand } from "../../services/IBrand";
import { IQuotationRequest } from "../../services/IQuotation";
import { IPostalCode } from "../../services/PostalCode";
import ButtonLiderar from "../ButtonLiderar/ButtonLiderar";
import { IDestino } from "../../services/IDestino";
import { ICuitOnline } from "../../services/CuitOnline";
import useAgeVerification from "../../shared/hooks/useAgeVerification";
import "./style.less";
import useCuitVerification from "../../shared/hooks/useCuitVerification";
import { IAppSetting } from "../../services/AppSetting";
import SelectFetchOnEnter from "../utils/SelectFetchOnEnter";
import { useCheckAuthority } from "../../shared/CustomHooks";
import useFormatData from "../../shared/hooks/useFormatData";
import { initialAccessories } from "./PolizaLayout";
import { IPaymentInstallmentTypes } from "../../services/paymentInstallmentTypes";
import { IcoverageTypeSelect } from "../../services/ICoverageType";
import { ITowingServiceOption } from "../../services/towingServiceTypes";

const { Option } = Select;
const { Panel } = Collapse;

const Quotation = (routeProps: RouteConfigComponentProps): JSX.Element => {
  const {
    fiscalConditions,
    provinces,
    destinos,
    adjustmentRates,
    coveragePeriods,
    amountInsuredVariation,
    totalAccessoriesLimit,
    
  } = useQuotation();

  const { formatDataCuit } = useFormatData();
  const history = useHistory();
  const { customRequest } = GraphqlService();
  const { messageError } = CustomMessage();
  const { user } = useContext(ContextApp);

  const {
    setLoading,
    setVehicleInfo,
    setQuotationError,
    setContextFormData,
    contextFormData,
    setAccessories,
    setFiscalCondition,
    setQuotationRequest,
  } = useContext(ContextPoliza);

  const { verifyCuit, isLoading: isLoadingCuitVerification } =
    useCuitVerification();

  const [form] = Form.useForm();

  const [producer, setProducer] = useState<IProducer>();
  const [initialProducer, setInitialProducer] = useState<string>();

  const [totalAccessories, setTotalAccessories] = useState<number>(0);
  const [tires, setTires] = useState<string>("");
  const [gnc, setGnc] = useState<string>("");
  const [freezer, setFreezer] = useState<string>("");
  const [cupula, setCupula] = useState<string>("");

  const [vehicleBrand, setVehicleBrand] = useState<IBrand[]>([]);
  const [vehicleModel, setVehicleModel] = useState<string[]>([]);
  const [yearList, setYearList] = useState<string[]>([]);

  const [productCode, setProductCode] = useState<string>("");

  const [vehicleBrandSelected, setVehicleBrandSelected] = useState<string>("");
  const [provinceIdSelected, setProvinceIdSelected] = useState<number>(0);
  const [postalCodes, setPostalCodes] = useState<IPostalCode[]>([]);
  const [location, setLocation] = useState<string>("");
  const [codpostal, setCodPostal] = useState<string | undefined>("");

  const [loadingModels, setLoadingModels] = useState<boolean>(false);

  const [modelSelected, setModelSelected] = useState<string>("");
  const [amountInsured, setAmountInsured] = useState<string>("");
  const [amountInsuredModified, setAmountInsuredModified] =
    useState<string>("");

  const [maxAmountInsured, setMaxAmountInsured] = useState<number>(0);
  const [minAmountInsured, setMinAmountInsured] = useState<number>(0);

  const [vehicleDestinyFiltered, setVehicleDestinyFiltered] =
    useState<string[]>();

  const [vehicleTypes, setVehicleTypes] = useState<IVehicleType[]>([]);
  const [selectedVehicleInfo, setSelectedVehicleInfo] =
    useState<IbrandModelByBrandModelYear>();
  const [vehicleTypeIDLiderar, setVehicleTypeIDLiderar] = useState<string>("");
  const [vehicleUseIDLiderar, setVehicleUseIDLiderar] = useState<string>("");
  
  const [provinceIdLiderar, setProvinceIdLiderar] = useState<string>("");
  const [additionalHailChecked, setAdditionalHailChecked] =
    useState<boolean>(false);

  const [amountOutOfRange, setAmountOutOfRange] = useState<boolean>(false);
  const [accesoriesError, setAccesoriesError] = useState<boolean>(false);

  const [loadingCities, setLoadingCities] = useState<boolean>(false);
  const [loadingYears, setLoadingYears] = useState<boolean>(false);
  const [loadingVersion, setLoadingVersion] = useState<boolean>(false);
  const [vehicleVersionList, setVehicleVersionList] =
    useState<IbrandModelByBrandModelYear[]>();
  const [vehicleYearSelect, setVehicleYearSelect] = useState<boolean>(false);
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [citiesDisabled, setCitiesDisabled] = useState<boolean>(true);
  const selectRef = useRef<RefSelectProps>(null);
  const [initialFiscalID, setInitialFiscalID] = useState<string>();
  const [idsLiderarForMotorcycles, setIdsLiderarForMotorcycles] = useState<
    string[]
  >([]);
  const { ageVerification, isAdult } = useAgeVerification();

  const [paymentInstallmentTypeSelected, setPaymentInstallmentTypeSelected] = useState<string>("");
  const [paymentInstallmentTypes, setPaymentInstallmentTypes] = useState<IPaymentInstallmentTypes[]>([]);
  const [selectedValuePaymentInstallmentType, setSelectedValuePaymentInstallmentType] = useState(producer?.tipo_emision || 'V2');
  const [filteredCoveragePeriods, setFilteredCoveragePeriods] = useState<ICoveragePeriod[]>([]);

  const [coveragePeriodSelected, setCoveragePeriodSelected] = useState<string>("");
  const [coverageTypeSelected, setCoverageTypeSelected] = useState<string>("");
  
  const handlePaymentInstallmentTypeChange = (value : string) => {
    setSelectedValuePaymentInstallmentType(value);
  };
  const [towingServiceOptions, setTowingServiceOptions] = useState<ITowingServiceOption[]>([]);
  const getCurrentDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const hasTowingService = async () => {
    try {
      console.log('Por llamar a towing');
      if(coverageTypeSelected==""){
        return;
      }
      console.log('tenemos contextFormData?.PERIODO_COB', coverageTypeSelected);
      console.log(coveragePeriodSelected);
      const data: any = await customRequest({
        query: Query.towingServices,
        variables: {
          input: {
            CODPROD: productCode, //"08644",
            FECEMI: getCurrentDate(), //"03/06/2024",
            MARCA: selectedVehicleInfo?.id_liderar, //"CHEVROLET CORSA BASE2 1.4 4P L/09 CLASSIC",
            ANIO: selectedYear, //"2010",
            // PATENTE: "IVD205",
            TIPOVEH: vehicleTypeIDLiderar, //"001",
            COBER: coverageTypeSelected, //"00115", 
            USO: vehicleUseIDLiderar, //"01",
            LOCALIDAD: location, //"VILLA MERCEDES",
            COD_PCIA: provinceIdLiderar, //"12"
          }
        
        }
      });
      //recupero las opciones de TowingServices

      let options: ITowingServiceOption[] = [];
      if(data.resp == 'NO'){
        options.push({code:'N', description:'NO'})
        form.setFieldsValue({ REMOLQUE_CC: "N" });
      }else if(data.resp == 'SI'){ 
        options.push({code:'S', description:'SI'})
        form.setFieldsValue({ REMOLQUE_CC: "S" });
      }else{
       options = await customRequest({
        query: Query.towingServicesOptions,
        variables: {
          input: data.resp
        }
      });
    
      console.log('ya paso towingOptions');
      console.log(options)

      if(options.length==1 && options[1].code !== 'N' && options[1].code !== 'S'){
        options.push({code:'N', description:'NO'})
      }
    }
      setTowingServiceOptions(options);
      console.log(towingServiceOptions);

      if(contextFormData?.REMOLQUE_CC){
        form.setFieldsValue({ REMOLQUE_CC: contextFormData.REMOLQUE_CC });
      }
    } catch (error) {
      messageError({
        context: "hasTowingService",
        message: "Error al obtener tipos de servicio de acarreo",
      });
    }
  };
  
  
  // Codigo productor -> producer
  // Marca y Modelo (id_Liderar) -> selectedVehicleInfo
  // Año ->  selectedYear
  // Patente -> ??
  // Tipo Vehiculo ->  vehicleTypeIDLiderar ?
  // Uso ->  contextFormData?.USO ?? vehicleUseIDLiderar
  // Código de Cobertura-> contextFormData?.PERIODO_COB
  // Localidad -> location
  // Código de Provincia ->  provinceIdLiderar
  useEffect(() => {
    console.log("entro - se llama a hastowingServices??")
    if(producer && selectedVehicleInfo && location !== "" && selectedYear && vehicleTypeIDLiderar != "" && vehicleUseIDLiderar !="" && coverageTypeSelected != ""
    ){
      hasTowingService();
    }else{
      resetRemoque();
    }
  }, [producer,selectedVehicleInfo, provinceIdLiderar, location, selectedYear, vehicleTypeIDLiderar, vehicleUseIDLiderar, coverageTypeSelected ]);

/// Comienza agregado CoverageType
const setCoverageTypeOption = (value: SelectValue) => {
  setCoverageTypeSelected(value?.toString() || '');
}
const [coverageType, setCoverageType] = useState<IcoverageTypeSelect[]>([]);
  const coverageTypeOptions = () =>
    coverageType.length !== 0 &&
    coverageType.map((item, index) => (
      <Option value={item.id_liderar} key={index}>
        {item.description.toLocaleUpperCase()}
      </Option>
    ));
      const getCoverageTypes = async (soloRC?: boolean) => {
        try {
          // CAK
          // const soloRCFilter =
          //   formType === EnumsValues.formType.directEmission ||
          //   formType === EnumsValues.formType.renovation;
          const soloRCFilter = true;
          
          const response: IcoverageTypeSelect[] = await customRequest({
            query: Query.coverageTypes,
            variables: {
              // CAK Revisar
              // coverage: renovationData
              //   ? renovationData.policy?.vehiculo[0].cobertura_codigo.slice(-2)
              //   : undefined,
                coverage:undefined,
              soloRC: soloRCFilter ? soloRC : undefined,
            },
          });
          setCoverageType(response);
        } catch (error) {
          messageError({
            context: "getCoverages",
            message: "Error al obtener los tipos de cobertura",
          });
        }
      }
    //  , [renovationData, formType]
    // );
    /// FIN agregado CoverageType
  //cak 2
  const getPaymentInstallmentType = async () => {
    try {
      const data: IPaymentInstallmentTypes[] = await customRequest({
        query: Query.paymentInstallmentTypes,
      });
      setPaymentInstallmentTypes(data);
    } catch (error) {
      messageError({
        context: "payment_installment_types",
        message: "Error al obtener tipos de cuotas",
      });
    }
  };
  const paymentInstallmentTypeOptions = () =>
    paymentInstallmentTypes.length !== 0 &&
paymentInstallmentTypes.map((item: IPaymentInstallmentTypes) => (
      <Option value={item.code} key={item.id}>
        {item.name}
      </Option>
    ));
    
  const handleSelectPaymentInstallmentType = (code: string) => {
    setPaymentInstallmentTypeSelected(code);
  }
  useEffect(() => {
    if (paymentInstallmentTypes.length && paymentInstallmentTypeSelected) {
      form.setFieldsValue({
        ['TIPO_EMISION']: paymentInstallmentTypeSelected,
      });
    }
  }, [paymentInstallmentTypeSelected]);

  useEffect(() => {
    if (user && user.producers && user.producers.length === 1) {
      setInitialProducer(user.producers[0].id_liderar);
      setTimeout(()=>{
        setPaymentInstallmentTypeSelected(user.producers[0].id_liderar);
      },500)
    }
  }, [user]);

  useEffect(() =>{
    verificarMoto()
  }, [coveragePeriods]);

  useEffect(() => {
    getVehicleBrands();
    getPaymentInstallmentType();
  }, []);

  useEffect(() => {
    if (selectedVehicleInfo) {
      const indexYearFilter: number | undefined =
        selectedVehicleInfo &&
        selectedVehicleInfo.valid_years_array.findIndex((year: any) => {
          if (year === selectedYear) {
            return year;
          }
        });

      if (indexYearFilter !== undefined) {
        const amountIn: string | undefined =
          selectedVehicleInfo &&
          selectedVehicleInfo.amount_insured_array[indexYearFilter];

        if (amountIn === "0") {
          form.setFieldsValue({
            AJU_AUT: "000",
            cupulaAccessorie: "0",
            freezerAccessorie: "0",
            gncAccessorie: "0",
            llantasAccessorie: "0",
            SUMA_ASEG: amountIn,
          });
          setAccesoriesError(false);
          setTires("");
          setGnc("");
          setFreezer("");
          setCupula("");
          setAmountInsured(amountIn);
          setAmountInsuredModified(amountIn);
          setTotalAccessories(0);

          setAmountOutOfRange(false);
        }
        if (amountIn && amountIn !== "0") {
          form.setFieldsValue({
            SUMA_ASEG: amountIn,
          });

          setAmountInsured(amountIn);
          setAmountInsuredModified(amountIn);
          setAmountOutOfRange(false);
        }
      }
      getVehicleType(selectedVehicleInfo?.vehicle_type_array);
    }
  }, [selectedVehicleInfo]);

  // Se recuperan los datos del formulario almacenados en el contexto

  useEffect(() => {
    getCoverageTypes();
    if (contextFormData?.ADIC_GRANIZO && !form.getFieldsValue().ADIC_GRANIZO) {
      handleChangeAdditionalHail(true);
      form.setFieldsValue({ ADIC_GRANIZO: contextFormData.ADIC_GRANIZO });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        ADIC_GRANIZO: undefined,
      }));
    }

    if (contextFormData?.MARCA && !form.getFieldsValue().MARCA) {
      handleChangeVehicleBrand(contextFormData.MARCA);
      form.setFieldsValue({ MARCA: contextFormData.MARCA });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        MARCA: undefined,
      }));
    }
    if (contextFormData?.PERIODO_COB && !form.getFieldsValue().PERIODO_COB) {
      form.setFieldsValue({ PERIODO_COB: contextFormData.PERIODO_COB });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        PERIODO_COB: undefined,
      }));
    }
    if (contextFormData?.AJU_AUT && !form.getFieldsValue().AJU_AUT) {
      form.setFieldsValue({ AJU_AUT: contextFormData.AJU_AUT });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        AJU_AUT: undefined,
      }));
    }
    if (contextFormData?.COND_FISCAL && !form.getFieldsValue().COND_FISCAL) {
      form.setFieldsValue({ COND_FISCAL: contextFormData.COND_FISCAL });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        COND_FISCAL: undefined,
      }));
    }
    if (
      contextFormData?.cupulaAccessorie &&
      !form.getFieldsValue().cupulaAccessorie
    ) {
      setCupula(contextFormData.cupulaAccessorie);
      form.setFieldsValue({
        cupulaAccessorie: contextFormData.cupulaAccessorie,
      });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        cupulaAccessorie: undefined,
      }));
    }
    if (
      contextFormData?.freezerAccessorie &&
      !form.getFieldsValue().freezerAccessorie
    ) {
      setFreezer(contextFormData.freezerAccessorie);
      form.setFieldsValue({
        freezerAccessorie: contextFormData.freezerAccessorie,
      });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        freezerAccessorie: undefined,
      }));
    }
    if (
      contextFormData?.gncAccessorie &&
      !form.getFieldsValue().gncAccessorie
    ) {
      setGnc(contextFormData.gncAccessorie);
      form.setFieldsValue({ gncAccessorie: contextFormData.gncAccessorie });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        gncAccessorie: undefined,
      }));
    }
    if (
      contextFormData?.llantasAccessorie &&
      !form.getFieldsValue().llantasAccessorie
    ) {
      setTires(contextFormData.llantasAccessorie);
      form.setFieldsValue({
        llantasAccessorie: contextFormData.llantasAccessorie,
      });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        llantasAccessorie: undefined,
      }));
    }
    if (contextFormData?.CUIT && !form.getFieldsValue().CUIT) {
      setInitialFiscalID(contextFormData.CUIT.value);
      form.setFieldsValue({ CUIT: contextFormData.CUIT });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        CUIT: undefined,
      }));
    }
    if (contextFormData?.COD_PROD && !form.getFieldsValue().COD_PROD) {
      setInitialProducer(contextFormData.COD_PROD);
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        COD_PROD: undefined,
      }));
    }


    console.log('se carga coverage')
    if (contextFormData?.coverage && !form.getFieldsValue().coverage) {
      setCoverageTypeOption(contextFormData?.coverage);
      form.setFieldsValue({ coverage: contextFormData?.coverage });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        coverage: contextFormData?.coverage,
      }));
    }
  }, []);

  useEffect(() => {
    if (
      contextFormData?.COD_PROV &&
      provinces?.length !== EnumsValues.ConstNumbers.zero &&
      !form.getFieldsValue().COD_PROV
    ) {
      handleChangeProvince(contextFormData.COD_PROV);
      form.setFieldsValue({ COD_PROV: contextFormData.COD_PROV });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        COD_PROV: undefined,
      }));
    }
  }, [provinces]);

  useEffect(() => {
    if (
      contextFormData?.MODELO &&
      vehicleModel?.length &&
      !form.getFieldsValue().MODELO
    ) {
      handleChangeVehicleModelSelect(contextFormData.MODELO);
      form.setFieldsValue({ MODELO: contextFormData.MODELO });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        MODELO: undefined,
      }));
    }
  }, [vehicleModel]);

  useEffect(() => {
    if (modelSelected) {
      if (contextFormData?.ANIO && !form.getFieldsValue().ANIO) {
        handleChangeVehicleYear(contextFormData.ANIO);
        form.setFieldsValue({ ANIO: contextFormData.ANIO });
        setContextFormData((oldContextFormData: IForm) => ({
          ...oldContextFormData,
          ANIO: undefined,
        }));
      }
    }
  }, [modelSelected]);

  useEffect(() => {
    if (
      contextFormData?.VERSION &&
      vehicleVersionList?.length &&
      !form.getFieldsValue().VERSION
    ) {
      handleChangeVersion(contextFormData.VERSION);
      form.setFieldsValue({ VERSION: contextFormData.VERSION });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        VERSION: undefined,
      }));
    }
  }, [vehicleVersionList]);

  useEffect(() => {
    if (
      contextFormData?.TIPO_VEHICULO &&
      vehicleTypes?.length &&
      !form.getFieldsValue().TIPO_VEHICULO
    ) {
      handleChangeVehicleType(contextFormData.TIPO_VEHICULO);

      form.setFieldsValue({ TIPO_VEHICULO: contextFormData.TIPO_VEHICULO });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        TIPO_VEHICULO: undefined,
      }));
    }
  }, [vehicleTypes]);

  useEffect(() => {
    console.log('compuebo uso')
    if (
      contextFormData?.USO &&
      destinos?.length &&
      vehicleDestinyFiltered?.length &&
      !form.getFieldsValue().USO
    ) {
      form.setFieldsValue({ USO: contextFormData.USO });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        USO: undefined,
      }));
    }

    // if(contextFormData?.USO && destinos?.length && form.getFieldsValue().USO){
    //   hasTowingService();
    // }
  }, [destinos, vehicleDestinyFiltered]);

  useEffect(() => {
    if (
      contextFormData?.LOCALIDAD &&
      !form.getFieldsValue().LOCALIDAD &&
      provinces.length && // Para no ser sobreescrito luego
      postalCodes.length
    ) {
      handleChangeLocationSelect(contextFormData.LOCALIDAD);
      form.setFieldsValue({ LOCALIDAD: contextFormData.LOCALIDAD });
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        LOCALIDAD: undefined,
      }));
    }
  }, [provinceIdSelected, provinces, postalCodes]);

  // Se termina con la recuperación de datos del formulario

  const getVehicleType = useCallback(
    async (id_liderar: string[]) => {
      await customRequest({
        query: Query.vehicle_types,
        variables: {
          filter: {
            activo_web: true,
            id_liderar: id_liderar,
            user_id: user?.id,
          },
          orderBy: { field: "description", direction: "asc" },
        },
      })
        .then((response: IVehicleType[]) => {
          if (response && response.length > 0) {
            setVehicleTypes(response.filter((item) => { if(item.id_liderar !== '080' && item.id_liderar !== '081' && item.id_liderar !== '082') { return true} else {return false}}));
          }
        })
        .catch(() => {
          messageError({
            context: "getVehicleType",
            message: "Error al obtener los tipos de vehículos",
          });
        });
    },
    [user]
  );

  const getVehicleBrands = useCallback(async () => {
    await customRequest({
      query: Query.brands,
    })
      .then((response: IBrand[]) => {
        if (response && response.length > 0) {
          setVehicleBrand(response);
        } else {
          setVehicleBrand([]);
        }
      })
      .catch(() => {
        messageError({
          context: "getVehicleBrands",
          message: "Error al obtener las marcas de vehículos",
        });
      });
  }, []);

  const getBrandModel = useCallback(async (brandSelected: string) => {
    setVehicleBrandSelected(brandSelected);
    setLoadingModels(true);
    await customRequest({
      query: Query.brandModelByBrandList,
      variables: {
        brand: brandSelected,
      },
    })
      .then((response: IbrandModelByBrandList) => {
        if (response && response.array_model.length > 0) {
          setVehicleModel(response.array_model);
        } else {
          setVehicleModel([]);
        }
      })
      .catch(() => {
        messageError({
          context: "brandModelByBrandList",
          message: "Error al obtener los modelos de vehículos",
        });
      })
      .finally(() => {
        setLoadingModels(false);
      });
  }, []);

  const getYearsByBrandAndModel = useCallback(
    async (brandSelectedParam: string, modelSelectedParam: string) => {
      setLoadingYears(true);
      await customRequest({
        query: Query.yearsByBrandAndModel,
        variables: {
          brand: brandSelectedParam,
          model: modelSelectedParam,
        },
      })
        .then((response: IyearsByBrandAndModel) => {
          if (response && response.array_year.length > 0) {
            setYearList(response.array_year);
          } else {
            setYearList([]);
          }
        })
        .catch(() => {
          messageError({
            context: "yearsByBrandAndModel",
            message:
              "Error al obtener el listado de años disponibles para los vehículos seleccionados",
          });
        })
        .finally(() => {
          setLoadingYears(false);
        });
    },
    []
  );

  const getBrandModelByBrandModelYear = useCallback(
    async (
      brandSelectedParam: string,
      modelSelectedParam: string,
      yearSelectedParam: string
    ) => {
      setLoadingVersion(true);
      await customRequest({
        query: Query.brandModelByBrandModelYear,
        variables: {
          brand: brandSelectedParam,
          model: modelSelectedParam,
          year: yearSelectedParam,
        },
      })
        .then((response: IbrandModelByBrandModelYear[]) => {
          if (response && response.length > 0) {
            setVehicleVersionList(response);
          } else {
            setVehicleVersionList([]);
          }
        })
        .catch(() => {
          messageError({
            context: "brandModelByBrandModelYear",
            message: "Error al obtener version de vehículos",
          });
        })
        .finally(() => {
          setLoadingVersion(false);
        });
    },
    []
  );

  const handleProducerSearch = (value: string): Promise<any[]> => {
    return new Promise((resolve) => {
      if (Number.isNaN(value)) {
        return resolve([]);
      }
      if (!value) {
        return resolve([]);
      }
      return customRequest({
        query: Query.producers,
        variables: {
          searchText: value,
          filter: {
            user_id: user?.id,
            activo_web: true,
          },
        },
      })
        .then((data: IProducer[]) => {
          resolve(data);
        })
        .catch(() => {
          messageError({
            context: "handleProducerSearch",
            message: "Error al obtener los productores",
          });
        });
    });
  };
  const resetRemoque = () => {
    setTowingServiceOptions([]);
    form.setFieldsValue({
      REMOLQUE_CC: undefined
    });
    setContextFormData((oldContextFormData: IForm) => ({
      ...oldContextFormData,
      REMOLQUE_CC: undefined,
    }));
  }
  const handleChangePeriodoCobertura = async (value: string) => {
    setCoveragePeriodSelected(value);
  }
  const handleChangeVehicleUse = async (value: string) => {
    // const valueNumber = Number(value);
    setVehicleUseIDLiderar(value);
  }
  const handleChangeVehicleType = async (value: string) => {
    form.setFieldsValue({ USO: undefined });

    const valueNumber = Number(value);

    let vehicle: IVehicleType;
    const vehicleLiderar: IVehicleType[] =
      vehicleTypes &&
      vehicleTypes.filter((vehicleType: IVehicleType) => {
        if (vehicleType.id === valueNumber) {
          vehicle = vehicleType;
        }
        return vehicle;
      });
    if (vehicleLiderar[0]?.destiny_array && vehicleLiderar[0]?.id_liderar) {
      setVehicleDestinyFiltered(vehicleLiderar[0].destiny_array);
      setVehicleTypeIDLiderar(vehicleLiderar[0].id_liderar);
      //CAK ACAAAA
      setContextFormData((oldContextFormData: IForm) => ({
        ...oldContextFormData,
        USO: undefined,
      }));
      return true;
    }
    return false;
  };

  const getIdsLiderarForMotorcycles = async () => {
    await customRequest({
      query: Query.getAppSettingByKey,
      variables: {
        input: { key: EnumsValues.SettingKeys.idsLiderarVehicleTypeMotorcycle },
      },
    })
      .then((data: IAppSetting) => {
        if (data) {
          setIdsLiderarForMotorcycles(() =>
            data.setting_value.replace(/ /g, "").split(",")
          );
        }
      })
      .catch((error: any) => {
        messageError({
          context: "Emission.idsLiderarVehicleTypeMotorcycle.1",
          message: error?.message,
        });
      });
  };

  useEffect(() => {
    getIdsLiderarForMotorcycles();
  }, []);

  const getProductCode = useCallback((): void => {
    if (idsLiderarForMotorcycles.includes(vehicleTypeIDLiderar)) {
      producer && setProductCode(producer?.cod_prod_moto);
    } else {
      producer && setProductCode(producer?.cod_prod_auto);
    }
  }, [producer, idsLiderarForMotorcycles, vehicleTypeIDLiderar]);

  const verificarMoto = useCallback((): void => {
    if (idsLiderarForMotorcycles.includes(vehicleTypeIDLiderar)) {
      //Agregamos periodo de cobertura para motos
      setFilteredCoveragePeriods(coveragePeriods);
    } else {
      //Removemos periodo de cobertura para motos
      setFilteredCoveragePeriods(coveragePeriods.filter(item => item.id_liderar !== 'T'));
    }
    // if(vehicleUseIDLiderar && towingService == "") {
    //   hasTowingService()
    // }
  }, [vehicleTypeIDLiderar]);
  useEffect(() => {
    if ( idsLiderarForMotorcycles && vehicleTypeIDLiderar) {
      verificarMoto();
    }
  }, [ idsLiderarForMotorcycles, vehicleTypeIDLiderar]);


  useEffect(() => {
    if (producer && idsLiderarForMotorcycles && vehicleTypeIDLiderar) {
      getProductCode();
    }
  }, [producer, idsLiderarForMotorcycles, vehicleTypeIDLiderar]);

  useEffect(() => {
    form.setFieldsValue({
      PRODUCTO: productCode,
    });
  }, [productCode]);

  const handleChangeProvince = (value: any) => {
    form.setFieldsValue({ LOCALIDAD: undefined });
    setProvinceIdSelected(value);
    const selectedProvince = provinces.filter(
      (province) => province.id === value
    );

    setProvinceIdLiderar(selectedProvince[0].id_liderar);
  };

  const handleChangeVehicleBrand = async (value: any) => {
    form.setFieldsValue({
      MODELO: undefined,
      ANIO: undefined,
      VERSION: undefined,
      TIPO_VEHICULO: undefined,
      USO: undefined,
      SUMA_ASEG: undefined,
      PERIODO_COB: undefined,
      REMOLQUE_CC: undefined
    });
    setVehicleModel([]);
    setVehicleYearSelect(false);
    setModelSelected("");
    setVehicleTypeIDLiderar("");
    setSelectedVehicleInfo(undefined);
    setTowingServiceOptions([]);
    //Al no saber tipo de vehículo, limpio períodos de cobertura
    setFilteredCoveragePeriods([]);
    setAmountOutOfRange(false);
    await getBrandModel(value);
  };

  const handleChangeVehicleModelSelect = async (selectedModel: string) => {
    form.setFieldsValue({
      ANIO: undefined,
      VERSION: undefined,
      TIPO_VEHICULO: undefined,
      USO: undefined,
      SUMA_ASEG: undefined,
    });
    setAmountOutOfRange(false);
    setVehicleYearSelect(false);
    setSelectedVehicleInfo(undefined);
    setVehicleTypeIDLiderar("");
    //Al no saber tipo de vehículo, limpio períodos de cobertura
    setFilteredCoveragePeriods([]);

    const modelSelectedFilter = vehicleModel.find((model: string) => {
      if (model === selectedModel) {
        return model;
      }
    });

    if (modelSelectedFilter) {
      setModelSelected(modelSelectedFilter);
    }
    await getYearsByBrandAndModel(vehicleBrandSelected, selectedModel);
  };

  const handleChangeVehicleYear = async (yearSelected: string) => {
    form.setFieldsValue({
      VERSION: undefined,
      TIPO_VEHICULO: undefined,
      USO: undefined,
      SUMA_ASEG: undefined,
    });
    setAmountOutOfRange(false);
    setSelectedYear(yearSelected);
    setVehicleYearSelect(true);
    setSelectedVehicleInfo(undefined);
    //Al no saber tipo de vehículo, limpio períodos de cobertura
    setFilteredCoveragePeriods([]);

    await getBrandModelByBrandModelYear(
      vehicleBrandSelected,
      modelSelected,
      yearSelected
    );
  };

  const handleChangeVersion = (vehicleVersionSelected: string) => {
    form.setFieldsValue({
      TIPO_VEHICULO: undefined,
      USO: undefined,
    });
    
    const selectedVehicles = vehicleVersionList?.find(
      (vehicle: IbrandModelByBrandModelYear) =>
        vehicle.model === vehicleVersionSelected
    );
    if (selectedVehicles) {
      setSelectedVehicleInfo(selectedVehicles);
    }
  };

  const handleChangeAdditionalHail = (value: boolean) => {
    setAdditionalHailChecked(value);
  };

  const handleChangeAccesories = useCallback(() => {
    const total = +cupula + +gnc + +freezer + +tires;

    const accesoriesLimit =
      (+amountInsuredModified * Number(totalAccessoriesLimit?.setting_value)) /
      100;

    if (total > accesoriesLimit) {
      setAccesoriesError(true);
    } else {
      setAccesoriesError(false);
    }

    setTotalAccessories(total);
  }, [
    cupula,
    gnc,
    freezer,
    tires,
    amountInsuredModified,
    totalAccessoriesLimit?.setting_value,
  ]);

  useEffect(() => {
    if (amountInsuredModified) {
      handleChangeAccesories();
    }
  }, [amountInsuredModified]);

  const handleChangeAmountInsured = useCallback(
    (value: any) => {
      const maxAmount: number = getMaxAmount(
        +amountInsured,
        Number(amountInsuredVariation?.setting_value)
      );
      const minAmount: number = getMinAmount(
        +amountInsured,
        Number(amountInsuredVariation?.setting_value)
      );

      setMaxAmountInsured(maxAmount);
      setMinAmountInsured(minAmount);

      if (+value > maxAmount) {
        setAmountOutOfRange(true);
      } else if (+value < minAmount) {
        setAmountOutOfRange(true);
      } else {
        setAmountOutOfRange(false);
      }
      setAmountInsuredModified(value);
    },
    [amountInsured]
  );

  const coverageDescription = (value: string): string => {
    const coveragePeriodsFilter: ICoveragePeriod[] = coveragePeriods.filter(
      (coveragePeriod: ICoveragePeriod) => {
        if (coveragePeriod.id_liderar === value) {
          return coveragePeriod;
        }
      }
    );
    return coveragePeriodsFilter[0].description;
  };

  const getLocationByProvince = useCallback(async () => {
    setLoadingCities(true);
    setCitiesDisabled(true);
    await customRequest({
      query: Query.locationByProvinceId,
      variables: {
        provinceId: provinceIdSelected,
      },
    })
      .then((response: IPostalCode[]) => {
        if (response && response.length > 0) {
          setPostalCodes(response);
          setCitiesDisabled(false);
        }
      })
      .catch(() => {
        messageError({
          context: "getLocationByProvince",
          message: "Error al obtener los codigos postales",
        });
      })
      .finally(() => {
        setLoadingCities(false);
      });
  }, [provinceIdSelected]);

  const handleChangeLocationSelect = (value: any) => {
    const locationSelectedFilter = postalCodes.find((item: any) => {
      if (item.location.toUpperCase() === value) {
        return item;
      }
    });
    setLocation(String(locationSelectedFilter?.location));
    provinceIdSelected === 2
      ? setCodPostal("1121")
      : setCodPostal(locationSelectedFilter?.codpostal);
  };
  let params: IQuotationInput;

  const onFinish = async (values: IForm) => {
    if (form.getFieldsValue().CUIT && !isAdult) {
      return messageError({
        context: "onFinish",
        message: "Debe ser mayor de edad para cotizar",
      });
    }

    setQuotationError(false);
    setQuotationRequest(undefined);
    setAccessories(initialAccessories);
    await setContextFormData({ ...values, coverage: coverageTypeSelected});
    setLoading(true);

    const code_acc: string[] = [];
    const value_acc: string[] = [];

    if (gnc) {
      code_acc.push("001");
      value_acc.push(String(gnc));
    }

    if (tires) {
      code_acc.push("004");
      value_acc.push(String(tires));
    }

    if (freezer) {
      code_acc.push("006");
      value_acc.push(String(freezer));
    }

    if (cupula) {
      code_acc.push("014");
      value_acc.push(String(cupula));
    }

    setAccessories({
      dome: cupula ? cupula : "0",
      cold_equipment: freezer ? freezer : "0",
      cng: gnc ? gnc : "0",
      special_tires: tires ? tires : "0",
      total_accessories: String(
        value_acc.reduce((acc, curr) => Number(acc) + Number(curr), 0)
      ),
    });

    params = {
      CUIT: values.CUIT !== undefined ? values.CUIT.label[0] : "",
      RAZON_SOCIAL: values.CUIT !== undefined ? values.CUIT.label[2] : "",
      COD_PROD: String(producer?.id_liderar),
      ZONA: String(producer?.zona_riesgo),
      COD_PROV: provinceIdLiderar,
      LOCALIDAD: location,
      COS_POS: String(codpostal),
      COND_FISCAL: values.COND_FISCAL,
      PERIODO_COB: values.PERIODO_COB,
      PRODUCTO: values.PRODUCTO,
      TIPO_VEH: String(vehicleTypeIDLiderar),
      MARCA: values.MARCA,
      MODELO: values.VERSION,
      ANIO: values.ANIO,
      SUMA_ASEG: values.SUMA_ASEG,
      AJU_AUT: values.AJU_AUT,
      USO: values.USO,
      COD_ACC: code_acc,
      SUMA_ACC: value_acc,
      ADIC_GRANIZO: values.ADIC_GRANIZO ? "S" : "N",
      TIPO_EMISION: values.TIPO_EMISION,
      REMOLQUE_CC: values.REMOLQUE_CC === 'N' || values.REMOLQUE_CC === 'S' ? values.REMOLQUE_CC.trim()  : 'S'+values.REMOLQUE_CC.trim()
    };
    
    setVehicleInfo({
      brand: params.MARCA,
      model: params.MODELO,
      year: params.ANIO,
      amount_insured: params.SUMA_ASEG,
      period: coverageDescription(params.PERIODO_COB),
      adjustment_rate: params.AJU_AUT,
      remolque_cc: values.REMOLQUE_CC
    });

    routeProps.history.push("/app/policy/quotation-details");
    await customRequest({
      mutation: Mutation.createQuotationRequest,
      variables: {
        input: params,
      },
    })
      .then((response: IQuotationRequest) => {
        setQuotationRequest(response);
      })
      .catch(() => {
        setQuotationError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleChangeAccesories();
  }, [cupula, gnc, freezer, tires]);

  useEffect(() => {
    if (provinceIdSelected !== 2) getLocationByProvince();
  }, [provinceIdSelected]);

  useEffect(() => {
    if (provinceIdSelected === 2) {
      setPostalCodes([]);
      setCodPostal("1121");
      setLocation("CAPITAL");
      form.setFieldsValue({
        LOCALIDAD: "CAPITAL FEDERAL",
      });
      setCitiesDisabled(false);
    }
  }, [provinceIdSelected]);

  useEffect(() => {
    if (producer) {
      form.setFieldsValue({
        ZONA: producer.zona_riesgo,
        COD_PROD: producer.id_liderar,
        TIPO_EMISION:producer?.tipo_emision || 'V2',
      });
    }
    setSelectedValuePaymentInstallmentType(producer?.tipo_emision || 'V2');
    
  }, [producer]);

  // const isList = (value: string) => {
  //   return value.includes(',');
  // };

  const renderRemolqueComponent = () => {
    if (towingServiceOptions.length > 1) {
      return (
        <Select onSelect={(item) => {
          form.setFieldsValue({ REMOLQUE_CC: item });
        }}>
          <Option key="no" value="N">No</Option>
          {towingServiceOptions.map((option, index) => (
            <Option key={index} value={option.code}>
              {option.description} ({option.code})
            </Option>
          ))}
        </Select>
      );

    } else if(towingServiceOptions.length == 1){
      console.log(towingServiceOptions)
      form.setFieldsValue({ REMOLQUE_CC: towingServiceOptions[0].code });
      return (<Input
                      placeholder=""
                      className="inputForm"
                      value={towingServiceOptions[0].description}
                      disabled={true}
                    />)
    } else {
      return (<Input
        placeholder=""
        className="inputForm"
        disabled={true}
      />)
    }
  };

  return (
    useCheckAuthority(routeProps) || (
      <div className="Quotation">
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={40}>
            <Col>
              <div className="producer_search_input">
                <p>Productor</p>
                <Form.Item
                  name="COD_PROD"
                  className="input-with-dropdown"
                  rules={[{ required: true, message: "Ingrese productor" }]}
                >
                  <SelectWithFetch
                    lengthValueToFetch={3}
                    onSelect={(value: IProducer) => setProducer(value)}
                    query={handleProducerSearch}
                    placeholder={
                      producer
                        ? producer.nombre
                        : "Ingrese nombre/número de productor"
                    }
                    optionRender={(item: IProducer) => (
                      <Option value={item.id_liderar} key={item.id_liderar}>
                        {camelCase(item.nombre)} - {item.id_liderar}
                      </Option>
                    )}
                    onClear={() => {
                      setProducer(undefined);
                      setProductCode("");
                      setInitialProducer(undefined);
                    }}
                    {...(initialProducer
                      ? { initialValue: initialProducer }
                      : {})}
                    {...(initialProducer
                      ? { initialData: [initialProducer] }
                      : {})}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col>
              {producer && (
                <Form.Item
                  className="risk_zone"
                  label="Zona Riesgo"
                  name="ZONA"
                >
                  {<p> {producer?.zona_riesgo} </p>}
                </Form.Item>
              )}
            </Col>
            <Col>
              {productCode && (
                <Form.Item
                  className="risk_zone"
                  label="Cod Producto"
                  name="PRODUCTO"
                >
                  <p> {productCode} </p>
                </Form.Item>
              )}
            </Col>
          </Row>

          <div className="quotation-container">
            <div className="customer-container">
              <p className="form-subtitle">Cliente</p>
              <Row gutter={40} className="customer-row">
                <Col xs={24} md={12} xl={6} className="customer-col">
                  <Form.Item
                    label="CUIT / CUIL / DNI"
                    name="CUIT"
                    className="input-with-dropdown"
                    style={{ display: "flex" }}
                  >
                    <SelectFetchOnEnter
                      query={verifyCuit}
                      isLoading={isLoadingCuitVerification}
                      onChange={(_, data: ICuitOnline[]) => {
                        const birthDate = data[0]?.birthDate;
                        ageVerification(birthDate);
                      }}
                      functionToFormatData={formatDataCuit}
                      placeholder="Ingrese CUIT, CUIL o DNI"
                      optionRender={(item: ICuitOnline, index: number) => (
                        <Option value={item.fiscalID} key={index}>
                          {item.fiscalID} - {item.name}
                        </Option>
                      )}
                      onClear={() => {
                        setInitialFiscalID(undefined);
                      }}
                      initialValue={initialFiscalID || null}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6} className="customer-col">
                  <Form.Item
                    label="Condición fiscal"
                    name="COND_FISCAL"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Seleccione"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      dropdownMatchSelectWidth={false}
                      className="selectForm"
                      aria-placeholder="Seleccione"
                      onSelect={(_, option) => {
                        setFiscalCondition(option.children);
                      }}
                    >
                      {fiscalConditions.length !== 0 &&
                        fiscalConditions.map((item: IFiscalCondition) => (
                          <Option value={item.id_liderar} key={item.id}>
                            {item.description}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6} className="customer-col">
                  <Form.Item
                    label="Provincia"
                    name="COD_PROV"
                    rules={[
                      { required: true, message: "Seleccione provincia" },
                    ]}
                  >
                    <Select
                      placeholder="Seleccione"
                      onChange={handleChangeProvince}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      dropdownMatchSelectWidth={false}
                      aria-placeholder="Seleccione"
                    >
                      {provinces.length !== 0 &&
                        provinces.map((item: IProvince) => (
                          <Option value={item.id} key={item.id}>
                            {camelCase(item.description)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6} className="customer-col">
                  <Form.Item
                    label="Localidad"
                    name="LOCALIDAD"
                    rules={[{ required: true, message: "Ingrese localidad" }]}
                  >
                    <Select
                      showSearch
                      placeholder="Seleccione"
                      onSelect={handleChangeLocationSelect}
                      aria-placeholder="Seleccione"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      loading={loadingCities}
                      disabled={citiesDisabled}
                    >
                      {postalCodes.length !== 0 &&
                        postalCodes.map((item: IPostalCode, index: number) => (
                          <Option value={item.location} key={index}>
                            {camelCase(item.location)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            {/* ----------------END CUSTOMER INFO----------------- */}

            <Divider />
            <div className="customer-vehicle-info">
              <p className="form-subtitle">Vehículo</p>
              <Row gutter={40} className="vehicle-row">
                <Col xs={24} md={12} xl={6} className="vehicle-col">
                  <Form.Item
                    label="Marca"
                    name="MARCA"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione marca del vehículo",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Seleccione"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      onSelect={handleChangeVehicleBrand}
                    >
                      {vehicleBrand.length !== 0 &&
                        vehicleBrand.map((item: any, index: number) => (
                          <Option value={item.brand} key={index}>
                            {item.brand}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6} className="vehicle-col">
                  <Form.Item
                    label="Modelo"
                    name="MODELO"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione modelo del vehículo",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Seleccione"
                      onSelect={handleChangeVehicleModelSelect}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      dropdownMatchSelectWidth={false}
                      loading={loadingModels}
                      disabled={vehicleModel.length === 0}
                    >
                      {vehicleModel.length !== 0 &&
                        vehicleModel.map((item: string, index: number) => (
                          <Option value={item} key={index}>
                            {item}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} xl={6} className="vehicle-col">
                  <Form.Item
                    label="Año"
                    name="ANIO"
                    rules={[{ required: true, message: "Seleccione año" }]}
                  >
                    <Select
                      placeholder="Seleccione"
                      onSelect={handleChangeVehicleYear}
                      loading={loadingYears}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      dropdownMatchSelectWidth={false}
                      disabled={!modelSelected}
                    >
                      {yearList.length !== 0 &&
                        yearList.map((item: string, index: number) => {
                          return (
                            <Option value={item} key={index}>
                              {item}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6} className="vehicle-col">
                  <Form.Item
                    label="Versión"
                    name="VERSION"
                    rules={[{ required: true, message: "Seleccione versión" }]}
                  >
                    <Select
                      placeholder="Seleccione"
                      onSelect={handleChangeVersion}
                      loading={loadingVersion}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      dropdownMatchSelectWidth={false}
                      disabled={!vehicleYearSelect}
                    >
                      {vehicleVersionList &&
                        vehicleVersionList.map(
                          (
                            item: IbrandModelByBrandModelYear,
                            index: number
                          ) => {
                            return (
                              <Option value={item.model} key={index}>
                                {item.model}
                              </Option>
                            );
                          }
                        )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6} className="vehicle-col">
                  <Form.Item
                    label="Tipo de vehículo"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione tipo de vehículo",
                      },
                    ]}
                    name="TIPO_VEHICULO"
                  >
                    <Select
                      placeholder="Seleccione"
                      onSelect={handleChangeVehicleType}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      dropdownMatchSelectWidth={false}
                      disabled={!selectedVehicleInfo}
                    >
                      {vehicleTypes.map((item: IVehicleType) => (
                        <Option value={item.id} key={item.id_liderar}>
                          {item.description}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6} className="vehicle-col">
                  <Form.Item
                    label="Destino"
                    name="USO"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione destino del vehículo",
                      },
                    ]}
                  >
                    <Select
                      ref={selectRef}
                      placeholder="Seleccione"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      dropdownMatchSelectWidth={false}
                      disabled={vehicleTypeIDLiderar === ""}
                      onSelect={handleChangeVehicleUse}
                    >
                      {vehicleDestinyFiltered &&
                        destinos
                          .filter((destiny) =>
                            vehicleDestinyFiltered.includes(destiny.id_liderar)
                          )
                          .map((item: IDestino) => (
                            <Option value={item.id_liderar} key={item.id}>
                              {item.description}
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6} className="vehicle-col">
                <Form.Item
                rules={[
                  { required: true, message: "Seleccione tipo de cobertura" },
                ]}
                label="Tipo de cobertura"
                name="coverage"
              >
                <Select
                  placeholder="Seleccione tipo de cobertura"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  dropdownMatchSelectWidth={false}
                  // disabled={readOnlyOr}
                  // className={readOnlyOr ? "read-only" : undefined}
                  onChange={(value) => {
                    console.log('tipo de cobertura: ' + value);
                    setCoverageTypeOption(value);
                    // cleanAdittionalOnCoverageRC(value);
                    // if (renovationData?.brand_model.amount_insured && value) {
                    //   checkIfRenovationNeedToBeBlocked(
                    //     renovationData?.brand_model.amount_insured,
                    //     String(value)
                    //   );
                    // }
                  }}
                >
                  {coverageTypeOptions()}
                </Select>
              </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6} className="vehicle-col">
                  <Form.Item
                    label="Período de cobertura"
                    name="PERIODO_COB"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione período de cobertura",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Seleccione"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      dropdownMatchSelectWidth={false}
                      onSelect={handleChangePeriodoCobertura}
                    >
                      {filteredCoveragePeriods.length !== 0 &&
                        filteredCoveragePeriods.map((item: any) => (
                          <Option value={item.id_liderar} key={item.id}>
                            {item.description}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                
              </Row>
              <Row gutter={40} className="second-vehicle-row">
              <Col xs={24} md={12} xl={6} className="vehicle-col">
                  <Form.Item
                    label="Cláusula de ajuste"
                    name="AJU_AUT"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione cláusula de ajuste",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Seleccione"
                      disabled={amountInsured === "0"}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      dropdownMatchSelectWidth={false}
                    >
                      {adjustmentRates.length !== 0 &&
                        adjustmentRates.map((item: any) => (
                          <Option value={item.description} key={item.id}>
                            {item.description}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} xl={6} className="vehicle-col">
                  <Form.Item
                    label={
                      <>
                        <span>Suma asegurada</span>
                        {amountOutOfRange ? (
                          <Tag className="tagAmountOutOfRange" color="error">
                            MONTO INVÁLIDO
                          </Tag>
                        ) : (
                          ""
                        )}
                      </>
                    }
                    name="SUMA_ASEG"
                  >
                    <Input
                      placeholder="Suma asegurada"
                      className="inputForm"
                      onChange={(e) =>
                        handleChangeAmountInsured(e.target.value)
                      }
                      contentEditable={true}
                      max={maxAmountInsured}
                      min={minAmountInsured}
                      formNoValidate={true}
                      disabled={!modelSelected || amountInsured === "0"}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[{ required: true, message: "Seleccione tipo de cuotas" }]}
              label='Tipo de cuotas'
              name='TIPO_EMISION'
            >
              <div style={{display:"none"}}>{selectedValuePaymentInstallmentType}</div>
              <Select
                placeholder='Seleccione tipo de cuotas'
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                onSelect={handleSelectPaymentInstallmentType}
                disabled={false}
                className={undefined}
                value={selectedValuePaymentInstallmentType} 
                onChange={handlePaymentInstallmentTypeChange}
              >
                {paymentInstallmentTypeOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
                  <Form.Item 
                  rules={[
                    {
                      required: true,
                      message: "Seleccione servicio de remolque",
                    },
                  ]}
                  label="Servicio de Remolque" name="REMOLQUE_CC">
                    {renderRemolqueComponent() }
                  
                  </Form.Item>
          </Col>
                
              </Row>
              <Row gutter={40} className="second-vehicle-row">
              <Col xs={24} md={12} xl={6} className="vehicle-col">
                  <Form.Item label="Adicional granizo" name="ADIC_GRANIZO">
                    <Switch
                      checked={additionalHailChecked}
                      onChange={handleChangeAdditionalHail}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            {/* END VEHICLE INPUTS */}
            <Divider />
            <Collapse bordered={false} ghost>
              <Panel header="Accesorios" key="1">
                <Row gutter={40} className="accessories-row">
                  <Col xs={24} md={12} xl={6}>
                    <Form.Item
                      className="accessories-item"
                      label="Cúpula"
                      name="cupulaAccessorie"
                      rules={[{ required: false }]}
                    >
                      <InputNumber
                        aria-label="Accesorios del vehiculo"
                        className="inputForm"
                        placeholder="$"
                        value={cupula}
                        controls={false}
                        onChange={setCupula}
                        disabled={!modelSelected || amountInsured === "0"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} xl={6}>
                    <Form.Item
                      className="accessories-item"
                      label="Equipo de frio"
                      name="freezerAccessorie"
                      rules={[{ required: false }]}
                    >
                      <InputNumber
                        aria-label="Accesorios del vehiculo"
                        className="inputForm"
                        placeholder="$"
                        value={freezer}
                        controls={false}
                        onChange={setFreezer}
                        disabled={!modelSelected || amountInsured === "0"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} xl={6}>
                    <Form.Item
                      className="accessories-item"
                      label="GNC"
                      name="gncAccessorie"
                      rules={[{ required: false }]}
                    >
                      <InputNumber
                        aria-label="Accesorios del vehiculo"
                        className="inputForm"
                        placeholder="$"
                        value={gnc}
                        controls={false}
                        onChange={setGnc}
                        disabled={!modelSelected || amountInsured === "0"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} xl={6}>
                    <Form.Item
                      className="accessories-item"
                      label="Llantas especiales"
                      name="llantasAccessorie"
                      rules={[{ required: false }]}
                    >
                      <InputNumber
                        aria-label="Accesorios del vehiculo"
                        className="inputForm"
                        placeholder="$"
                        value={tires}
                        controls={false}
                        onChange={setTires}
                        disabled={!modelSelected || amountInsured === "0"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} xl={6} className="total-accesories">
                    <Form.Item
                      className="accessories-item"
                      label={
                        <>
                          <span>Total de los accesorios:</span>
                          {accesoriesError ? (
                            <Tag className="tagAmountOutOfRange" color="error">
                              MONTO EXCEDIDO
                            </Tag>
                          ) : (
                            ""
                          )}
                        </>
                      }
                      name="SUMA_ACC"
                      rules={[{ required: false }]}
                    >
                      <p className="total-amount">
                        ${totalAccessories.toLocaleString("es-AR")}
                      </p>
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </div>
          <p
            style={{
              backgroundColor: "white",
              padding: 10,
              borderRadius: 10,
              marginTop: 20,
            }}
          >
            NO IMPLICA ACEPTACIÓN DE LAS COBERTURAS COTIZADAS, QUEDANDO
            SUPEDITADAS A LAS PAUTAS DE SUSCRIPCIÓN DE LA COMPAÑÍA
          </p>
          <div className="buttons">
            <ButtonLiderar
              color="white"
              buttonName="Volver"
              onClick={() => history.goBack()}
            />
            <ButtonLiderar
              color="violet"
              buttonName="Cotizar"
              className="form-button search-button"
              htmlType="submit"
              disabled={amountOutOfRange || accesoriesError}
            />
          </div>
        </Form>
      </div>
    )
  );
};

export default Quotation;
